.indexedStep {
    width: 70px;
    height: 70px;
    border-radius: 70%;
    background: linear-gradient(210deg, #B0E0E6, #87CEEB, #007bff, #0476D0);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 255, 0.3);
    cursor: pointer;
}

.indexedStep.accomplished {
    background-color: #664de5;
    color: white;
    border-style: none;
}

.indexedStep.disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    box-shadow: none;
}

.RSPBprogressBar {
    height: 5px;
    width: 70%;
    line-height: 2;
    border-radius: 10px;
    position: relative;
    background-color: rgb(207, 207, 207);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    margin: 20px auto;
}

.RSPBprogressBar .RSPBstep {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: absolute;
    transform: translateX(-50%);
    transition-property: all;
    transition-timing-function: ease;
}

.RSPBprogressBar .RSPBprogressBarText {
    color: white;
    font-size: 15px;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.RSPBprogressBar .RSPBprogression {
    position: absolute;
    transition: width 0.3s ease;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    background: #664DE5;
    z-index: -1;
}

@media screen and (max-width: 480px) {
    .indexedStep{
        width: 15px;
        height: 15px;
        font-size: 6px;
    }
}

.step-label {
    margin-top: 5px;
    font-size: 18px;
    font-weight: 600;
    color: #022F53;
    text-align: center;
}

.indexedStep {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
  
.step-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.step-label-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
}

.steps {
    width: 32%;
    font-size: 18px;
    font-weight: 600;
    color: #022F53;
    text-align: center;
    /* border: 1px solid black; */
    padding: 0 20px 10px 20px;
    word-wrap: break-word; 
}